// Entry point for importing non-rendering scss.
// Import this file if you'd like to use bulma variables, mixins, etc in another component or project without dealing with all of the rendered bulma css

// Import functions, required by some vars
@import '~bulma/sass/utilities/functions';

// Import inputs & variables first so they'll override bulma defaults
@import '../../scss/inputs';
@import '../../scss/variables';

// Import Bulma's utils (default variables, mixins, etc)
@import '~bulma/sass/utilities/_all';

// Custom mixins, functions
@import '../../scss/mixins';
@import '../../scss/svg-utils';

@import "./../../scss/imports";

.basic-layout {
	$real-bottom-padding:1.4rem;
	$bottom-padding-offset:6.56rem;

	width:100%;
	min-height:100vh;
	display:flex;
	flex-direction:column;

	&__head {
		background-color:$dark;
		display:flex;
		flex-direction:row;
		justify-content: center;
	}

	&__logo-link {
		display:block;
		padding:2rem 0 ($real-bottom-padding + $bottom-padding-offset);
	}

	.gf-logo {
		width:240px;
	}

	&__body {
		flex:1 1 auto;
		margin-top:$bottom-padding-offset * -1;
		padding-bottom:3rem;

		@include until($tablet - 1) {
			padding:0 1rem 2rem;
		}
	}
}
