// Entry point for importing non-rendering scss.
// Import this file if you'd like to use bulma variables, mixins, etc in another component or project without dealing with all of the rendered bulma css

// Import functions, required by some vars
@import '~bulma/sass/utilities/functions';

// Import inputs & variables first so they'll override bulma defaults
@import '../../src/scss/inputs';
@import '../../src/scss/variables';

// Import Bulma's utils (default variables, mixins, etc)
@import '~bulma/sass/utilities/_all';

// Custom mixins, functions
@import '../../src/scss/mixins';
@import '../../src/scss/svg-utils';

.component-library {
	&__strength {
		max-width:400px;
	}
}
