// Entry point for importing non-rendering scss.
// Import this file if you'd like to use bulma variables, mixins, etc in another component or project without dealing with all of the rendered bulma css

// Import functions, required by some vars
@import '~bulma/sass/utilities/functions';

// Import inputs & variables first so they'll override bulma defaults
@import '../../scss/inputs';
@import '../../scss/variables';

// Import Bulma's utils (default variables, mixins, etc)
@import '~bulma/sass/utilities/_all';

// Custom mixins, functions
@import '../../scss/mixins';
@import '../../scss/svg-utils';

@import './../../scss/imports';

.validation-indicator {
	$vi:&;

	display:flex;
	flex-direction: row;
	align-items: flex-start;

	&__label {
		flex:1 1 auto;
		margin-left:.2rem;
	}

	&.is-muted {
		color:$grey;
	}

	&.is-success {
		color:$success;
	}

	&.is-danger {
		color:$danger;
	}

	&__icon {
		// f
	}
}
