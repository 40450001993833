// Additions to bulma go here. Custom button types and stuff like that.
.navbar-item,
.navbar-link {
	// This is an explicit hack, non-standard values are fine here
	/* stylelint-disable */
	font-size: 18px;
	/* stylelint-enable */
	line-height: 1em;
	padding: $navbar-padding-vertical $navbar-item-padding-horizontal;

	&.is-fat {
		padding: $navbar-padding-vertical 45px;
	}
}

// This creates color-variant navbar items and dropdowns. In retrospect I see why the buefy team didn't attempt this.
@each $name, $pair in $colors {
	$color: nth($pair, 1);
	$color-invert: nth($pair, 2);
	$color-light: scale-color($color, $lightness: 8%);
	$color-dark: scale-color($color, $lightness: -8%);

	.is-#{$name} {
		&.navbar-item.has-dropdown > .navbar-link,
		&.navbar-item:not(.has-dropdown),
		&.navbar-link {
			// Alternatives to !important were even worse here
			/* stylelint-disable declaration-no-important */
			background-color: $color !important;
			color: $color-invert !important;

			&:focus,
			&:focus-within,
			&:hover,
			&.is-active {
				background-color: $color-light !important;
				color: $color-invert !important;

				> .navbar-link {
					background-color: $color-light !important;
					color: $color-invert !important;
					/* stylelint-enable declaration-no-important */
				}
			}
		}

		&.navbar-item > .navbar-link {
			&:not(.is-arrowless)::after {
				border-color: $color-invert;
			}
		}
	}
}

.body {
	background: $grey-lightest;
}

.title,
.subtitle {
	color: $header;

	&.is-0 {
		font-size: $size-0;
	}
}

.label {
	color: $text;
}

.is-yld-gradient {
	@include yld-gradient('bg');

	.title,
	.subtitle,
	p {
		color: $white;
	}

	.copy-block__divider {
		border-color: $white;
	}
}

.is-not-clickable {
	cursor: default;
}

.menu {
	li {
		a {
			font-weight: $weight-medium;

			&.icon-text {
				display: flex;
				align-items: center;
			}

			.icon {
				margin-right: 8px;
			}
		}
	}
}

.button {
	font-weight: $button-font-weight;

	// standard default button
	// (note: also adds popup hover state)
	&.is-primary {
		$color: $gf-blue-dark;
		$color-invert: $white;

		background: $gf-blue-dark;
		color: $white;
		margin-bottom: 1px;
		margin-right: 2px;
		transition: height 0.1s, margin 0.1s, padding 0.1s, box-shadow 0.1s,
			width 0.1s;

		&:not(.basic-hover) {
			&:hover,
			&.is-hovered {
				box-shadow: $gradient-button-shadow-hover;
			}
		}

		&.is-outlined {
			background-image: none;
			background-color: transparent;
			border-color: $color;
			color: $color;

			&:hover,
			&.is-hovered,
			&:focus,
			&.is-focused {
				background-color: $color;
				border-color: $color;
				color: $color-invert;
			}

			&.is-loading {
				&::after {
					/* stylelint-disable-next-line */
					border-color: transparent transparent $color $color !important;
				}

				&:hover,
				&.is-hovered,
				&:focus,
				&.is-focused {
					&::after {
						/* stylelint-disable-next-line */
						border-color: transparent transparent $color-invert $color-invert !important;
					}
				}
			}

			&[disabled],
			fieldset[disabled] & {
				background-color: transparent;
				border-color: $color;
				box-shadow: none;
				color: $color;
			}
		}

		&.is-inverted.is-outlined {
			background-color: transparent;
			border-color: $color-invert;
			color: $color-invert;

			&:hover,
			&.is-hovered,
			&:focus,
			&.is-focused {
				background-color: $color-invert;
				color: $color;
			}

			&.is-loading {
				&:hover,
				&.is-hovered,
				&:focus,
				&.is-focused {
					&::after {
						/* stylelint-disable-next-line */
						border-color: transparent transparent $color $color !important;
					}
				}
			}

			&[disabled],
			fieldset[disabled] & {
				background-color: transparent;
				border-color: $color-invert;
				box-shadow: none;
				color: $color-invert;
			}
		}
	}

	// standard default CTA gradient
	// (note: also adds popup hover state)
	&.is-gradient {
		$color: $groundfloor-primary-dark;
		$color-invert: $white;

		background: $gradient-button no-repeat;
		color: $white;
		margin-bottom: 1px;
		margin-right: 2px;
		transition: height 0.1s, margin 0.1s, padding 0.1s, box-shadow 0.1s,
			width 0.1s;

		&:active,
		&.is-active {
			background: $gradient-button-active no-repeat;
		}

		&:not(.basic-hover) {
			&:hover,
			&.is-hovered {
				box-shadow: $gradient-button-shadow-hover;
				padding: 0.5em calc(1em + 1px);
				height: calc(2.5em + 3px);
				margin-bottom: 0;
				margin-right: 0;
				margin-top: -2px;
			}

			&.is-rounded {
				&:hover,
				&.is-hovered {
					padding: 0.5em calc(1em + 0.25em + 1px);
				}
			}

			&.is-fullwidth {
				&:hover,
				&.is-hovered {
					margin-left: -1px;
					margin-right: -1px;
					width: calc(100% + 2px);
				}
			}
		}

		&.is-outlined {
			background-image: none;
			background-color: transparent;
			border-color: $color;
			color: $color;

			&:hover,
			&.is-hovered,
			&:focus,
			&.is-focused {
				background-color: $color;
				border-color: $color;
				color: $color-invert;
			}

			&.is-loading {
				&::after {
					/* stylelint-disable-next-line */
					border-color: transparent transparent $color $color !important;
				}

				&:hover,
				&.is-hovered,
				&:focus,
				&.is-focused {
					&::after {
						/* stylelint-disable-next-line */
						border-color: transparent transparent $color-invert $color-invert !important;
					}
				}
			}

			&[disabled],
			fieldset[disabled] & {
				background-color: transparent;
				border-color: $color;
				box-shadow: none;
				color: $color;
			}
		}

		&.is-inverted.is-outlined {
			background-color: transparent;
			border-color: $color-invert;
			color: $color-invert;

			&:hover,
			&.is-hovered,
			&:focus,
			&.is-focused {
				background-color: $color-invert;
				color: $color;
			}

			&.is-loading {
				&:hover,
				&.is-hovered,
				&:focus,
				&.is-focused {
					&::after {
						/* stylelint-disable-next-line */
						border-color: transparent transparent $color $color !important;
					}
				}
			}

			&[disabled],
			fieldset[disabled] & {
				background-color: transparent;
				border-color: $color-invert;
				box-shadow: none;
				color: $color-invert;
			}
		}
	}

	// Optional gradients for standard colors (just in-case we want 'em)
	// if you couple 'is-gradient' with 'is-primary' you'll get a primary button but in a gradient format
	@each $name, $pair in $button-colors {
		$color: nth($pair, 1);
		$color-invert: nth($pair, 2);

		&.is-#{$name} {
			&.is-inverted {
				border-color: $color;
			}

			&.is-gradient {
				$gradient-color-1: bulmaDarken($color, 30%);
				$gradient-color-2: bulmaDarken($color, 20%);
				$gradient-color-3: bulmaDarken($color, 10%);
				$gradient-color-dark-1: bulmaDarken($color, 45%);
				$gradient-color-dark-2: bulmaDarken($color, 30%);
				$gradient-color-dark-3: bulmaDarken($color, 20%);

				border: 0;
				background: linear-gradient(
						301.89deg,
						$gradient-color-1 0%,
						$gradient-color-2 44.85%,
						$gradient-color-3 103.81%
					)
					no-repeat;
				color: $white;

				&:active,
				&.is-active {
					background: linear-gradient(
							301.89deg,
							$gradient-color-dark-1 0%,
							$gradient-color-dark-2 44.85%,
							$gradient-color-dark-3 103.81%
						)
						no-repeat;
				}
			}
		}
	}

	&.is-fat {
		$multi: 2;

		padding-left: $button-padding-horizontal * $multi;
		padding-right: $button-padding-horizontal * $multi;
	}

	// standard default CTA gradient
	// (note: also adds popup hover state)
	&.is-gradient {
		$color: $groundfloor-primary-dark;
		$color-invert: $white;

		background: $gradient-button no-repeat;
		color: $white;
		margin-bottom: 1px;
		margin-right: 2px;
		transition: height 0.1s, margin 0.1s, padding 0.1s, box-shadow 0.1s,
			width 0.1s;

		&:active,
		&.is-active {
			background: $gradient-button-active no-repeat;
		}

		&:not(.basic-hover) {
			&:hover,
			&.is-hovered {
				box-shadow: $gradient-button-shadow-hover;
				padding: 0.5em calc(1em + 1px);
				height: calc(2.5em + 3px);
				margin-bottom: 0;
				margin-right: 0;
				margin-top: -2px;
			}

			&.is-rounded {
				&:hover,
				&.is-hovered {
					padding: 0.5em calc(1em + 0.25em + 1px);
				}
			}

			&.is-fullwidth {
				&:hover,
				&.is-hovered {
					margin-left: -1px;
					margin-right: -1px;
					width: calc(100% + 2px);
				}
			}
		}

		&.is-outlined {
			background-image: none;
			background-color: transparent;
			border-color: $color;
			color: $color;

			&:hover,
			&.is-hovered,
			&:focus,
			&.is-focused {
				background-color: $color;
				border-color: $color;
				color: $color-invert;
			}

			&.is-loading {
				&::after {
					/* stylelint-disable-next-line */
					border-color: transparent transparent $color $color !important;
				}

				&:hover,
				&.is-hovered,
				&:focus,
				&.is-focused {
					&::after {
						/* stylelint-disable-next-line */
						border-color: transparent transparent $color-invert $color-invert !important;
					}
				}
			}

			&[disabled],
			fieldset[disabled] & {
				background-color: transparent;
				border-color: $color;
				box-shadow: none;
				color: $color;
			}
		}

		&.is-inverted.is-outlined {
			background-color: transparent;
			border-color: $color-invert;
			color: $color-invert;

			&:hover,
			&.is-hovered,
			&:focus,
			&.is-focused {
				background-color: $color-invert;
				color: $color;
			}

			&.is-loading {
				&:hover,
				&.is-hovered,
				&:focus,
				&.is-focused {
					&::after {
						/* stylelint-disable-next-line */
						border-color: transparent transparent $color $color !important;
					}
				}
			}

			&[disabled],
			fieldset[disabled] & {
				background-color: transparent;
				border-color: $color-invert;
				box-shadow: none;
				color: $color-invert;
			}
		}
	}

	// Optional gradients for standard colors (just in-case we want 'em)
	// if you couple 'is-gradient' with 'is-primary' you'll get a primary button but in a gradient format
	@each $name, $pair in $button-colors {
		$color: nth($pair, 1);
		$color-invert: nth($pair, 2);

		&.is-#{$name} {
			&.is-inverted {
				border-color: $color;
			}

			&.is-gradient {
				$gradient-color-1: bulmaDarken($color, 30%);
				$gradient-color-2: bulmaDarken($color, 20%);
				$gradient-color-3: bulmaDarken($color, 10%);
				$gradient-color-dark-1: bulmaDarken($color, 45%);
				$gradient-color-dark-2: bulmaDarken($color, 30%);
				$gradient-color-dark-3: bulmaDarken($color, 20%);

				border: 0;
				background: linear-gradient(
						301.89deg,
						$gradient-color-1 0%,
						$gradient-color-2 44.85%,
						$gradient-color-3 103.81%
					)
					no-repeat;
				color: $white;

				&:active,
				&.is-active {
					background: linear-gradient(
							301.89deg,
							$gradient-color-dark-1 0%,
							$gradient-color-dark-2 44.85%,
							$gradient-color-dark-3 103.81%
						)
						no-repeat;
				}
			}
		}
	}
}

.navbar-item {
	font-weight: $navbar-item-font-weight;
}

.has-text-weight-extrabold {
	font-weight: $weight-extrabold;
}

.has-text-weight-heavy {
	font-weight: $weight-heavy;
}

.card + .pagination {
	margin-top: 2rem;
}
