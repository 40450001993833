// Entry point for importing non-rendering scss.
// Import this file if you'd like to use bulma variables, mixins, etc in another component or project without dealing with all of the rendered bulma css

// Import functions, required by some vars
@import '~bulma/sass/utilities/functions';

// Import inputs & variables first so they'll override bulma defaults
@import '../scss/inputs';
@import '../scss/variables';

// Import Bulma's utils (default variables, mixins, etc)
@import '~bulma/sass/utilities/_all';

// Custom mixins, functions
@import '../scss/mixins';
@import '../scss/svg-utils';

@import './../scss/imports';

.gf-message {
	padding: 16px 20px;
	border-radius: 4px;
	border-width: 0 0 0 4px;
	border-style: solid;
}

.dark {
	background-color: $white;
	border-color: $grey-dark;
}

.info {
	background-color: $grey-lighter;
	border-radius: 0;
	border-color: $gf-blue-dark;
	color: $grey-dark;
}

.small {
	font-size: $size-8;
}
