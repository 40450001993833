// Entry point for importing non-rendering scss.
// Import this file if you'd like to use bulma variables, mixins, etc in another component or project without dealing with all of the rendered bulma css

// Import functions, required by some vars
@import '~bulma/sass/utilities/functions';

// Import inputs & variables first so they'll override bulma defaults
@import '../../scss/inputs';
@import '../../scss/variables';

// Import Bulma's utils (default variables, mixins, etc)
@import '~bulma/sass/utilities/_all';

// Custom mixins, functions
@import '../../scss/mixins';
@import '../../scss/svg-utils';

@import "./../../scss/imports";

.blur-overlay {
	position:fixed;
	top:0;
	bottom:0;
	right:0;
	left:0;
	z-index: $blur-z;
	background: rgba(64, 64, 65, .5);
	backdrop-filter: blur(4px);
	opacity: 1;
	transition: opacity .25s;

	&.v-enter-from,
	&.v-leave-to {
		opacity: 0;
	}
}
