// Entry point for importing non-rendering scss.
// Import this file if you'd like to use bulma variables, mixins, etc in another component or project without dealing with all of the rendered bulma css

// Import functions, required by some vars
@import '~bulma/sass/utilities/functions';

// Import inputs & variables first so they'll override bulma defaults
@import '../../scss/inputs';
@import '../../scss/variables';

// Import Bulma's utils (default variables, mixins, etc)
@import '~bulma/sass/utilities/_all';

// Custom mixins, functions
@import '../../scss/mixins';
@import '../../scss/svg-utils';

@import './../../scss/imports';

.fat-card {
	width:100%;
	margin:0 auto;

	&.card {
		max-width:$fat-card-max-width;
	}

	&__inner {
		margin:0 auto;
		padding:.75rem 0 2rem;

		@include from($tablet) {
			max-width:$fat-card-max-content;
		}
	}

	&.card + &.card {
		margin-top:1.75rem;
	}

	&__back {
		padding-bottom: 1rem;
		color:$grey;
		font-size:$size-7;

		a {
			@include link-inherit-color;
		}

		@include from($desktop) {
			padding-bottom: 0;
			position: absolute;
			left: 35px;
			top: 40px;
		}
	}

	&__icon {
		width: 6rem;
		margin: auto;
		display: flex;
		justify-content: center;
	}

	&__actions {
		margin-top: 4rem;

		@include until($desktop) {
			margin-top: 1rem;
		}

		.button + .button {
			margin-top:1rem;
		}
	}

	&__text-action {
		text-align:center;
		margin-top:1rem;
	}
}
