// Entry point for importing non-rendering scss.
// Import this file if you'd like to use bulma variables, mixins, etc in another component or project without dealing with all of the rendered bulma css

// Import functions, required by some vars
@import '~bulma/sass/utilities/functions';

// Import inputs & variables first so they'll override bulma defaults
@import '../scss/inputs';
@import '../scss/variables';

// Import Bulma's utils (default variables, mixins, etc)
@import '~bulma/sass/utilities/_all';

// Custom mixins, functions
@import '../scss/mixins';
@import '../scss/svg-utils';

@import '../../src/scss/imports';

.mobile-pagination {
	display:flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background-color:$white-ter;
	color:$grey;
	border-radius: $radius-rounded;
	padding:2.5px 0;

	&__count {
		flex:0 1 auto;
		text-align:center;
		padding:0 .5rem;
	}

	.button.light {
		color:$grey;
	}
}
