// Entry point for importing non-rendering scss.
// Import this file if you'd like to use bulma variables, mixins, etc in another component or project without dealing with all of the rendered bulma css

// Import functions, required by some vars
@import '~bulma/sass/utilities/functions';

// Import inputs & variables first so they'll override bulma defaults
@import '../../scss/inputs';
@import '../../scss/variables';

// Import Bulma's utils (default variables, mixins, etc)
@import '~bulma/sass/utilities/_all';

// Custom mixins, functions
@import '../../scss/mixins';
@import '../../scss/svg-utils';

@import "./../../scss/imports";

.lending-navbar {
	&__burger-item {
		&.navbar-item {
			padding-left:16px;
			padding-right:16px;
		}
	}

	&__nav-icon {
		color:$white;
		opacity: .8;
		transition: opacity .1s;

		&.is-active {
			opacity: 1;
		}
	}

	&__logo-item.navbar-item {
		padding-left:16px;
		padding-right:16px;
		display:flex;
		flex-direction: column;
		justify-content: center;
	}

	&__logo {
		&.gf-logo {
			width:185px;
		}
	}

	@include until($desktop) {
		&__logo {
			&.gf-logo {
				width:145px;
			}
		}

		&__logo-item.navbar-item {
			padding-left:0;
			padding-right:8px;
		}

		.navbar-menu {
			.navbar-item {
				&:last-child {
					margin-right:8px;
				}
			}
		}
	}

	@include from($desktop) {
		.navbar-menu {
			.navbar-item {
				&:last-child {
					margin-right:34px;
				}
			}
		}

		&__logo-item.navbar-item {
			padding-left:16px;
			padding-right:16px;
		}

		&__burger-item {
			&.navbar-item {
				margin-left:4px;
			}
		}

		&__mobile-drawer {
			display:none;
		}
	}
}
