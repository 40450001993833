// Entry point for importing non-rendering scss.
// Import this file if you'd like to use bulma variables, mixins, etc in another component or project without dealing with all of the rendered bulma css

// Import functions, required by some vars
@import '~bulma/sass/utilities/functions';

// Import inputs & variables first so they'll override bulma defaults
@import '../../scss/inputs';
@import '../../scss/variables';

// Import Bulma's utils (default variables, mixins, etc)
@import '~bulma/sass/utilities/_all';

// Custom mixins, functions
@import '../../scss/mixins';
@import '../../scss/svg-utils';

@import "./../../scss/imports";

.bottom-drawer {
	$border-radius:16px;

	&__wrap {
		position:fixed;
		bottom:0;
		left:0;
		right:0;
		z-index: $bottom-drawer-z;
		display:flex;
		flex-direction:row;
		justify-content: center;
	}

	&__slider {
		flex:1 1 auto;
		background-color:$white;
		border-top-left-radius: $border-radius;
		border-top-right-radius: $border-radius;
		box-shadow: 2px 4px 12px rgba(16, 54, 106, .3);
		margin-bottom:-100vh;
		transition:margin-bottom .25s;
		max-height:100vh;
		display:flex;
		flex-direction: column;
		align-items: stretch;

		&.is-active {
			margin-bottom:0;
		}
	}

	&__header {
		flex:0 0 auto;
		text-align: right;
		margin-bottom:8px;
		display:flex;
		flex-direction: row;
		justify-content: flex-end;
		padding:16px;
		padding-bottom:0;

		&-label {
			font-weight:$weight-bold;
		}

		&.has-label {
			padding:16px;
			border-top-left-radius: $border-radius;
			border-top-right-radius: $border-radius;
			justify-content: center;
			position:relative;
			background:$white-ter;
			margin-bottom:0;

			.bottom-drawer__header-close {
				position:absolute;
				right:16px;
			}
		}
	}

	&__content {
		flex:1 1 auto;
		padding:16px;
		overflow-y:auto;
	}
}
