// Like the name says, color stuff.
// Bulma color vars from initial-variables.sass and derived-variables.sass have been condensed into this file so everything can be viewed as a whole. It also makes the interdependencies easier to work with.
// NB: Just because a variable is defined in this file doesn't mean that it has been modified.

$black:         #212529;
$black-bis:     #3d4148;
$black-ter:     #5d5d5f;

$grey-darker: #202020;
$grey-dark: #404041;
$grey: #6c7584;
$grey-light: hsl(202, 18%, 70%);
$grey-lighter: #dfebf0;
$grey-lightest: hsl(200, 17%, 93%);

$white-ter: #f2f5f9;
$white-bis: #f9fafc;
$white: #fff;

$orange: #f45815;
$yellow: #ffc83e;
$green: #1bc15e;
$lime: #91ce18;
$turquoise: #00d1b2;
$cyan: #29ccc7;
$blue: #00baf8;
$blue-darker: #009dd1;
$purple: #b179ff;
$violet: #4845ba;
$red: #dc0404;

//// Brand Standard

$yld-cyan: $cyan;
$gf-blue: $blue;
$brand-purple: $purple;
$brand-aquamarine:$green;
$brand-lime: $lime;
$brand-green: $green;

//// Brand Light

$yld-cyan-light: #b0eae8;
$gf-blue-light: #7fdcfb;
$brand-purple-light: hsl(265, 100%, 87%);
$brand-aquamarine-light: hsl(145, 61%, 72%);
$brand-lime-light: hsl(80, 65%, 72%);
$brand-green-light: findLightColor($brand-green);

//// Brand Dark

$yld-cyan-dark: #15a29f;
$gf-blue-dark: #006dd1;
$brand-purple-dark: hsl(246, 49%, 61%);
$brand-aquamarine-dark: hsl(145, 62%, 35%);
$brand-lime-dark: hsl(86, 60%, 36%);
$brand-green-dark: findLightColor($brand-green);

//// Brand Inverted
$brand-green-invert: findColorInvert($brand-green);

//// Brand Gradients
$yld-gradient-top: $yld-cyan;
$yld-gradient-bot: $gf-blue;
$yld-chart-primary: $yld-cyan;
$yld-chart-gradient-top: $yld-cyan;
$yld-chart-gradient-bot: rgba($yld-cyan, 0);
$yld-chart-label: #077988;
$yld-alt-gradient-top: $gf-blue;
$yld-alt-gradient-bot: $brand-purple;

//// Button Gradient Defaults
$gradient-color-3: #4dbff3;
$gradient-color-2: #2f85d8;
$gradient-color-1: #0f47b3;
$gradient-color-3-dark: #409ac2;
$gradient-color-2-dark: #2467a8;
$gradient-color-1-dark: #071f4f;

//// Brand versions
$stairs-primary:$cyan;
$stairs-primary-invert:findColorInvert($stairs-primary);
$stairs-primary-light: $yld-cyan-light;
$stairs-primary-dark: $yld-cyan-dark;

$groundfloor-primary:$gf-blue;
$groundfloor-primary-invert:findColorInvert($groundfloor-primary);
$groundfloor-primary-light:$gf-blue-light;
$groundfloor-primary-dark:#006dd1;

$vapor: $purple;
$vapor-invert: findColorInvert($vapor);
$vapor-light: $brand-purple-light;
$vapor-dark: $brand-purple-dark;

// These nulls are here to break things if a bad color mode is selected
$primary: null;
$primary-invert: null;
$primary-light: null;
$primary-dark: null;

$secondary: null;
$secondary-invert: null;
$secondary-light: null;
$secondary-dark: null;

// Stairs Color mode
@if $color-mode-key == $stairs-color-mode-key {
	$primary: $stairs-primary;
	$primary-invert: $stairs-primary-invert;
	$primary-light: $stairs-primary-light;
	$primary-dark: $stairs-primary-dark;

	$secondary: $vapor;
	$secondary-invert: $vapor-invert;
	$secondary-light: $vapor-light;
	$secondary-dark: $vapor-dark;
}

// GF Color mode (default)
@else if($color-mode-key == $groundfloor-color-mode-key) {
	$primary: $groundfloor-primary;
	$primary-invert: $groundfloor-primary-invert;
	$primary-light: $groundfloor-primary-light;
	$primary-dark: $groundfloor-primary-dark;

	$secondary: $brand-green;
	$secondary-invert: $brand-green-invert;
	$secondary-light: $brand-green-light;
	$secondary-dark: $brand-green-dark;
}
// Add more colors modes here with @else if
// @else is intentionally omitted so a bad $color-mode-key will throw an error

// Semantics

$info: $gf-blue-light;
$success: #0a8a13;
$warning: $orange;
$danger: $red;

$light: $white-ter;
$dark: $grey-dark;

// Transparency

$light-transparent: rgba($light, .9);
$dark-transparent: rgba($dark, .1);

// Invert colors

$orange-invert: findColorInvert($orange);
$orange-light: #fef0ea;
$yellow-invert: findColorInvert($yellow);
$green-invert: findColorInvert($green);
$turquoise-invert: findColorInvert($turquoise);
$cyan-invert: findColorInvert($cyan);
$blue-invert: findColorInvert($blue);
$purple-invert: findColorInvert($purple);
$red-invert: findColorInvert($red);
$info-invert: findColorInvert($info);
$info-light: #e5f0fa;
$info-dark: findDarkColor($info);
$success-invert: findColorInvert($success);
$success-light: findLightColor($success);
$success-dark: findDarkColor($success);
$warning-invert: findColorInvert($warning);
$warning-light: #fef0ea;
$warning-dark: findDarkColor($warning);
$danger-invert: findColorInvert($danger);
$danger-light: #fdf2f2;
$danger-dark: findDarkColor($danger);
$light-invert: findColorInvert($light);
$dark-invert: findColorInvert($dark);

// General colors

$scheme-main: $white;
$scheme-main-bis: $white-bis;
$scheme-main-ter: $white-ter;
$scheme-invert: $black;
$scheme-invert-bis: $black-bis;
$scheme-invert-ter: $black-ter;

$background: $white-ter;

$border: $grey-lighter;
$border-hover: $grey-light;
$border-light: $grey-lightest;
$border-light-hover: $grey-light;

// Text colors

$header: $black;
$text: $black-bis;
$text-invert: findColorInvert($text);
$text-light: $grey;
$text-strong: $grey-darker;

// Code colors

$code: $red;
$code-background: $background;

$pre: $text;
$pre-background: $background;

// Link colors

$link: #1c5bff;
$link-footer: #00baf8;
$link-invert: findColorInvert($link);
$link-light: findLightColor($link);
$link-dark: findDarkColor($link);
$link-visited: $purple;

$link-hover: $link;
$link-hover-border: $grey-light;

$link-focus: $link;
$link-focus-border: $link;

$link-active: $link;
$link-active-border: $grey-dark;

// Lists and maps
$custom-colors: (
	'groundfloor-primary-dark' : ($groundfloor-primary-dark, $groundfloor-primary-invert, $groundfloor-primary, $gradient-color-1),
	'groundfloor-primary' : ($groundfloor-primary, $groundfloor-primary-invert, $groundfloor-primary-light, $groundfloor-primary-dark),
	'stairs-primary' : ($stairs-primary, $stairs-primary-invert, $stairs-primary-light, $stairs-primary-dark),
	'secondary' : ($secondary, $secondary-invert, $secondary-light, $secondary-dark),
	'vapor' : ($vapor, $vapor-invert, $vapor-light, $vapor-dark),
);
$custom-shades: (
	'grey-lightest': $grey-lightest,
	'dark-transparent': $dark-transparent,
	'light-transparent': $light-transparent,
);

$colors: mergeColorMaps(
	(
		'white'  : ($white, $black),
		'black'  : ($black, $white),
		'light'  : ($light, $light-invert),
		'dark'   : ($dark, $dark-invert),
		'primary': ($primary, $primary-invert, $primary-light, $primary-dark),
		'link'   : ($link, $link-invert, $link-light, $link-dark),
		'info'   : ($info, $info-invert, $info-light, $info-dark),
		'success': ($success, $success-invert, $success-light, $success-dark),
		'warning': ($warning, $warning-invert, $warning-light, $warning-dark),
		'danger' : ($danger, $danger-invert, $danger-light, $danger-dark),
	),
	$custom-colors
);

$snackbar-colors: mergeColorMaps(
	(
		'white'  : ($white, $black),
		'black'  : ($black, $white),
		'light'  : ($grey, $light-invert),
		'dark'   : ($dark, $dark-invert),
		'primary': ($primary, $primary-invert, $primary-light, $primary-dark),
		'link'   : ($link, $link-invert, $link-light, $link-dark),
		'info'   : ($info, $info-invert, $info-light, $info-dark),
		'success': ($secondary, $success-invert, $success-light, $success-dark),
		'warning': ($warning, $warning-invert, $warning-light, $warning-dark),
		'danger' : ($link, $danger-invert, $danger-light, $danger-dark),
	),
	$custom-colors
);

$shades: mergeColorMaps(
	(
		'black-bis'   : $black-bis,
		'black-ter'   : $black-ter,
		'grey-darker' : $grey-darker,
		'grey-dark'   : $grey-dark,
		'grey'        : $grey,
		'grey-light'  : $grey-light,
		'grey-lighter': $grey-lighter,
		'white-ter'   : $white-ter,
		'white-bis'   : $white-bis
	),
	$custom-shades
);

$all-colors: (
	'white'             : $white,
	'white-ter'         : $white-ter,
	'white-bis'         : $white-bis,
	'grey-darker'       : $grey-darker,
	'grey-dark'         : $grey-dark,
	'grey'              : $grey,
	'grey-light'        : $grey-light,
	'grey-lighter'      : $grey-lighter,
	'light'             : $light,
	'light-transparent' : $light-transparent,
	'dark-transparent'  : $dark-transparent,
	'primary'           : $primary,
	'primary-invert'    : $primary-invert,
	'primary-light'     : $primary-light,
	'primary-dark'      : $primary-dark,
	'secondary'         : $secondary,
	'secondary-invert'  : $secondary-invert,
	'secondary-light'   : $secondary-light,
	'secondary-dark'    : $secondary-dark,
);

$button-colors:$colors;
