// Entry point for importing non-rendering scss.
// Import this file if you'd like to use bulma variables, mixins, etc in another component or project without dealing with all of the rendered bulma css

// Import functions, required by some vars
@import '~bulma/sass/utilities/functions';

// Import inputs & variables first so they'll override bulma defaults
@import '../../scss/inputs';
@import '../../scss/variables';

// Import Bulma's utils (default variables, mixins, etc)
@import '~bulma/sass/utilities/_all';

// Custom mixins, functions
@import '../../scss/mixins';
@import '../../scss/svg-utils';

@import "./../../scss/imports";

.navbar-icon-dropdown {
	$border-radius:8px;

	.navbar-dropdown {
		top: calc(100% - 12px);
		min-width:264px;
		border-radius:$border-radius;
		box-shadow: 2px 4px 12px rgba(16, 54, 106, .3);
		padding:0;
	}

	&__icon {
		color:$white;
		opacity: .8;
		transition: opacity .1s;

		&.is-active {
			opacity: 1;
		}
	}

	&__header {
		background:$white-ter;
		color:$text;
		font-weight:$weight-bold;
		padding:16px;
		border-top-left-radius:$border-radius;
		border-top-right-radius:$border-radius;
		text-align: center;
	}

	&__content {
		padding:16px;
		padding-bottom:22px;
	}

	@include until($desktop) {
		&.navbar-item {
			.navbar-link {
				padding-left:8px;
				padding-right:8px;
			}
		}

		.navbar-dropdown {
			display:none !important; // stylelint-disable-line
		}
	}

	@include from($desktop) {
		&.navbar-item {
			.navbar-link {
				padding-left:16px;
				padding-right:16px;
			}
		}
	}
}
