// Overriding core bulma css should happen here

//Overriding css toast and snackbar

$success-box-shadow: 0 -2px 12px rgba($black, .16),
	0 4px 12px rgba($success, .2);
$info-box-shadow: 0 -2px 12px rgba($black, .16), 0 4px 12px rgba($info, .2);
$warning-box-shadow: 0 -2px 12px rgba($black, .16),
	0 4px 12px rgba($warning, .2);
$danger-box-shadow: 0 -2px 12px rgba($black, .16),
	0 4px 12px rgba($danger, .2);

.notices {
	padding: 1.5rem;
	padding-top: $navbar-height + 1.5rem;

	@include until($tablet) {
		padding: .5rem;
		padding-top: $navbar-height + .75rem;
	}

	.snackbar {
		background: $white;
		color: $grey;
		border-radius: $radius-small;

		@include tablet {
			min-width: 250px;
			max-width: 600px;
			overflow: hidden;
		}

		@include until($tablet) {
			width: auto;
		}

		.text {
			padding-right: 0;
		}

		&.is-success,
		&.is-info,
		&.is-warning,
		&.is-danger {
			background-repeat: no-repeat;
			background-size: 20px;
			background-position: 1em center;
			justify-content: space-between;
			align-items: center;

			.text {
				padding-left: 3em;
			}

			.action {
				padding: 0;
				margin-left: 0;
			}

			.button {
				background-color: $white;
				text-transform: none;

				&,
				&:hover,
				&:active {
					background: $white;
				}
			}

			.action .button {
				color: $grey;
			}
		}

		&.is-success {
			box-shadow: $success-box-shadow;
			background-image: url(svg-encode($success-check-svg));
		}

		&.is-info {
			box-shadow: $info-box-shadow;
			background-image: url(svg-encode($info-check-svg));
		}

		&.is-warning {
			box-shadow: $warning-box-shadow;
			background-image: url(svg-encode($warning-bang-svg));
		}

		&.is-danger {
			box-shadow: $danger-box-shadow;
			background-image: url(svg-encode($danger-bang-svg));
		}
	}
}

.navbar {
	&.is-dark {
		.navbar-burger {
			color: $secondary;

			span {
				height: 2px;
			}
		}
	}
}

// give cards fatter radiuses, and more padding
.card {
	$rad: 1rem;

	border-radius: $rad;

	.card-content {
		padding: 3rem;

		@include mobile {
			padding: 1.5rem;
		}

		.card-image:first-child img {
			border-top-left-radius: $rad;
			border-top-right-radius: $rad;
		}

		&:last-child,
		.card-footer:last-child,
		.card-header:last-child {
			border-bottom-left-radius: $rad;
			border-bottom-right-radius: $rad;
		}

		.card-footer:first-child,
		.card-content:first-child,
		.card-header:first-child {
			border-top-left-radius: $rad;
			border-top-right-radius: $rad;
		}
	}

	&.is-unrounded-mobile {
		@include touch {
			border-radius: 0;
			width: 100%;
		}
	}
}

p.help.is-danger {
	font-style: italic;
}

.field {
	&:not(:last-child) {
		margin-bottom: 1.25rem;
	}
}

.pagination-previous,
.pagination-next,
.pagination-link,
.pagination-ellipsis {
	border-radius: 0;
}

.pagination-link {
	&.is-current {
		font-weight: $weight-bold;
	}
}

.modal-card {
	$mcp-thin: 50px;
	$mcp-fat: 60px;
	$mcp-mobile: 40px;

	&-head,
	&-body,
	&-foot {
		padding: $mcp-fat;

		@include mobile {
			padding: $mcp-mobile;
			margin: 0 24px;
		}
	}

	&-head {
		padding-bottom: 0;

		@include mobile {
			padding-bottom: 0;
		}
	}

	&-body {
		border-radius: $modal-radius;
	}

	&-head + &-body {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		padding-top: $mcp-thin;
	}

	// There's no good way to target a body followed by a foot, so this will have to do
	&-body:not(:last-child) {
		padding-bottom: $mcp-thin;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	&-foot {
		padding-top: 0;

		@include mobile {
			padding-top: 0;
		}
	}

	&-head + &-foot {
		padding-top: $mcp-fat;
	}
}

// TODO : try to integrate this into variables
.menu-list {
	hr {
		background-color: $grey-light;
		margin: 16px 4px;
		height: 1px;
	}

	li {
		a {
			color: $text-light;
			border-radius: 8px;

			.icon {
				&:not(:last-child) {
					margin-right: 8px;
				}
			}

			&:hover,
			&.is-active {
				background-color: $info-light;
				color: $text;

				.icon {
					color: $groundfloor-primary-dark;
				}
			}

			&.is-active {
				font-weight: $weight-bold;
			}
		}
	}
}

.menu {
	&.is-flush-left {
		margin: 16px 16px 16px 0;

		hr {
			margin-left: 12px;
		}

		li {
			a {
				padding: 12px;
				padding-left: 20px;

				&:hover,
				&.is-active {
					border-radius: 0 12px 12px 0;
				}

				&:hover.is-expanded {
					background-color: transparent;
				}
			}

			ul {
				margin-top: 0;
				border-left: 0;
				padding-left: 0;
				margin-left: 0;

				a {
					// stylelint-disable-line no-descending-specificity
					padding-left: 44px;
				}
			}
		}
	}
}

.notification {
	a:not(.button):not(.dropdown-item) {
		// stylelint-disable-line no-descending-specificity
		text-decoration: none;
	}
}

.section {
	&.is-no-padding-mobile {
		@include touch {
			padding: 0;
		}
	}
}

.b-table {
	.table {
		th {
			font-weight: $weight-medium;
		}
	}
}
