// Entry point for importing non-rendering scss.
// Import this file if you'd like to use bulma variables, mixins, etc in another component or project without dealing with all of the rendered bulma css

// Import functions, required by some vars
@import '~bulma/sass/utilities/functions';

// Import inputs & variables first so they'll override bulma defaults
@import '../../scss/inputs';
@import '../../scss/variables';

// Import Bulma's utils (default variables, mixins, etc)
@import '~bulma/sass/utilities/_all';

// Custom mixins, functions
@import '../../scss/mixins';
@import '../../scss/svg-utils';

@import './../../scss/imports';

.strength-meter {
	width:100%;

	&__label {
		display: flex;
		justify-content: space-between;
	}

	&__progress {
		width: 100%;
		height: .75rem;
		-moz-appearance: none;
		-webkit-appearance: none;
		border: none;
		border-radius: 9999px;
		display: block;
		overflow: hidden;
		padding: 0;
	}

	progress::-webkit-progress-bar {
		background-color: $grey-lightest;
	}

	progress::-webkit-progress-value {
		background-color: $grey;
		transition: width .5s;
	}

	progress.is-success::-webkit-progress-value {
		background-color: $success;
	}

	progress.is-info::-webkit-progress-value {
		background-color: $info;
	}

	progress.is-warning::-webkit-progress-value {
		background-color: $warning;
	}

	progress.is-danger::-webkit-progress-value {
		background-color: $danger;
	}
}
