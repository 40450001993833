// Entry point for importing non-rendering scss.
// Import this file if you'd like to use bulma variables, mixins, etc in another component or project without dealing with all of the rendered bulma css

// Import functions, required by some vars
@import '~bulma/sass/utilities/functions';

// Import inputs & variables first so they'll override bulma defaults
@import '../../scss/inputs';
@import '../../scss/variables';

// Import Bulma's utils (default variables, mixins, etc)
@import '~bulma/sass/utilities/_all';

// Custom mixins, functions
@import '../../scss/mixins';
@import '../../scss/svg-utils';

@import './../../scss/imports';

.gf-field-new {
	// Removes the semantic icons from within the input that are automatically
	// added for semantic variants.
	.control.has-icons-right {
		& input.input:has(
		~ span.icon.is-right.has-text-danger,
		~ span.icon.is-right.has-text-warning,
		~ span.icon.is-right.has-text-success) {
			padding-right: .68em;
		}

		& span.icon.is-right {
			&.has-text-danger,
			&.has-text-warning,
			&.has-text-success {
				display: none;
			}
		}
	}

	&__sublabel {
		font-weight:$weight-normal;
		font-size:$size-7;
		font-style: italic;
		margin-left: .25em;
	}

	.help {
		display: flex;
	}
}
