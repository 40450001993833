// Entry point for importing non-rendering scss.
// Import this file if you'd like to use bulma variables, mixins, etc in another component or project without dealing with all of the rendered bulma css

// Import functions, required by some vars
@import '~bulma/sass/utilities/functions';

// Import inputs & variables first so they'll override bulma defaults
@import '../../scss/inputs';
@import '../../scss/variables';

// Import Bulma's utils (default variables, mixins, etc)
@import '~bulma/sass/utilities/_all';

// Custom mixins, functions
@import '../../scss/mixins';
@import '../../scss/svg-utils';

@import "./../../scss/imports";

/* stylelint-disable sh-waqar/declaration-use-variable */
// When this component is used inside a WC there are issues with REM units in rails as rails sets the root unit to 10px vs the standard 16px. Rem units in this file have be rewritten to compensate.

.settings-menu {
	padding:$navbar-item-padding-horizontal;

	&__menu-label {
		font-weight:$weight-semibold;
		font-size:24px;
		margin-bottom:4px;
	}

	.menu-list li {
		ul {
			border-left: none;
			padding-left: 0;
			margin:0;
		}

		a {
			display: flex;
			align-items: center;
		}

		&.is-not-clickable > a {
			cursor: inherit;
		}

		a.icon-text {
			&.is-active,
			&.is-expanded {
				color: $primary-dark;
				background-color: unset;
			}
		}
	}

	&__help {
		text-align:center;

		&-title {
			font-weight:$weight-semibold;
			font-size:20px;
			margin-bottom:8px;
		}

		&-options {
			display:flex;
			flex-direction:column;
			align-items:center;
			font-weight: $weight-semibold;
			font-size:12px;
		}
	}
}
/* stylelint-enable sh-waqar/declaration-use-variable */
