// Spacing
$success-check-svg: '<svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.719 9A8.719 8.719 0 1 1 .28 9a8.719 8.719 0 0 1 17.438 0Zm-9.727 4.617 6.468-6.47a.563.563 0 0 0 0-.795l-.795-.795a.562.562 0 0 0-.796 0l-5.275 5.275L5.13 8.37a.563.563 0 0 0-.796 0l-.795.796a.563.563 0 0 0 0 .795l3.656 3.656c.22.22.576.22.796 0Z" fill="#{$success}"/></svg>';

$info-check-svg: '<svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.719 9A8.719 8.719 0 1 1 .28 9a8.719 8.719 0 0 1 17.438 0Zm-9.727 4.617 6.468-6.47a.563.563 0 0 0 0-.795l-.795-.795a.562.562 0 0 0-.796 0l-5.275 5.275L5.13 8.37a.563.563 0 0 0-.796 0l-.795.796a.563.563 0 0 0 0 .795l3.656 3.656c.22.22.576.22.796 0Z" fill="#{$info}"/></svg>';

$warning-bang-svg: '<svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.719 9A8.718 8.718 0 0 1 9 17.719 8.718 8.718 0 0 1 .281 9 8.72 8.72 0 0 1 9 .281 8.72 8.72 0 0 1 17.719 9ZM9 10.758a1.617 1.617 0 1 0 0 3.234 1.617 1.617 0 0 0 0-3.234ZM7.465 4.945l.26 4.781a.422.422 0 0 0 .422.399h1.706a.422.422 0 0 0 .422-.399l.26-4.781a.422.422 0 0 0-.42-.445h-2.23a.422.422 0 0 0-.42.445Z" fill="#{$warning}"/></svg>';

$danger-bang-svg: '<svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.719 9A8.718 8.718 0 0 1 9 17.719 8.718 8.718 0 0 1 .281 9 8.72 8.72 0 0 1 9 .281 8.72 8.72 0 0 1 17.719 9ZM9 10.758a1.617 1.617 0 1 0 0 3.234 1.617 1.617 0 0 0 0-3.234ZM7.465 4.945l.26 4.781a.422.422 0 0 0 .422.399h1.706a.422.422 0 0 0 .422-.399l.26-4.781a.422.422 0 0 0-.42-.445h-2.23a.422.422 0 0 0-.42.445Z" fill="#{$danger}"/></svg>';

$fat-card-max-content:23.75rem;
$fat-card-max-width:38.75rem;

$blur-z:31;
$intercom-z:$blur-z + 1;
$above-blur-z:$blur-z + 4;
$bottom-drawer-z:$above-blur-z;
