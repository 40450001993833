// Entry point for importing non-rendering scss.
// Import this file if you'd like to use bulma variables, mixins, etc in another component or project without dealing with all of the rendered bulma css

// Import functions, required by some vars
@import '~bulma/sass/utilities/functions';

// Import inputs & variables first so they'll override bulma defaults
@import '../../../src/scss/inputs';
@import '../../../src/scss/variables';

// Import Bulma's utils (default variables, mixins, etc)
@import '~bulma/sass/utilities/_all';

// Custom mixins, functions
@import '../../../src/scss/mixins';
@import '../../../src/scss/svg-utils';


@import "../../../src/scss/imports";

.router-table {
	&__split-main,
	&__split-content {
		display:flex;
		flex-direction: column;
		align-items: center;

		.b-table,
		.card {
			width:100%;
		}

		.mobile-pagination {
			flex:0 1 auto;

			@include from($tablet) {
				display:none;
			}
		}
	}
}
