// Entry point for importing non-rendering scss.
// Import this file if you'd like to use bulma variables, mixins, etc in another component or project without dealing with all of the rendered bulma css

// Import functions, required by some vars
@import '~bulma/sass/utilities/functions';

// Import inputs & variables first so they'll override bulma defaults
@import '../../scss/inputs';
@import '../../scss/variables';

// Import Bulma's utils (default variables, mixins, etc)
@import '~bulma/sass/utilities/_all';

// Custom mixins, functions
@import '../../scss/mixins';
@import '../../scss/svg-utils';

$button-text: #fff;
$hover-background: #fff;
$subtitle-color: #1a3670;

@import './../../scss/imports';

.housingBanner {
	background: linear-gradient(90deg, #00baf8 0%, #006ed2 100%);
	color: $white;
	height:113px;
	margin-right: auto;
	margin-left: auto;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-size: $size-5;

	/* These are intended to make room for the auto-investor banner */
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: $blur-z;

	@include tablet {
		font-size: $size-6;
	}

	@include mobile {
		font-size: $size-7;
	}

	&__notification {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		width: 100%;
	}

	&__left {
		width: 338px;
		min-width: 338px;
		max-width: 338px;

		@include until ($desktop) {
			text-align: center;
			margin: 0 auto;
		}

		&-subtitle {
			font-size: $size-7;
			line-height: 16.8px;
			color: $subtitle-color;
			text-align: right;
			text-transform: uppercase;

			@include until ($desktop) {
				text-align: center;
			}
		}

		&-title {
			font-size: $size-4;
			line-height: 28.8px;
			text-align: right;

			@include until ($desktop) {
				text-align: center;
			}

			&-underline {
				@include mobile {
					text-decoration: underline;
				}
			}
		}
	}

	&__divider {
		width: .5px;
		border: 1px solid white;
		height: 55px;
		margin-right: 30px;
		margin-left: 30px;

		@include until ($tablet) {
			margin-right: 16px;
			margin-left: 16px;
		}
	}

	&__center {
		line-height: 21px;
		display: flex;
		flex-direction: column;
		max-width: 400px;

		&-learn-more {
			text-decoration: underline;
			font-weight: bold;
		}
	}

	&__right {
		&__button {
			margin-left: 50px;
		}

		.button.is-default {
			background: transparent;
			color: $button-text;
		}

		.button.is-default:hover {
			background: $hover-background;
			border-color: $hover-background;
			color: $black;
		}
	}
}
