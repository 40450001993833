// Entry point for importing non-rendering scss.
// Import this file if you'd like to use bulma variables, mixins, etc in another component or project without dealing with all of the rendered bulma css

// Import functions, required by some vars
@import '~bulma/sass/utilities/functions';

// Import inputs & variables first so they'll override bulma defaults
@import '../../scss/inputs';
@import '../../scss/variables';

// Import Bulma's utils (default variables, mixins, etc)
@import '~bulma/sass/utilities/_all';

// Custom mixins, functions
@import '../../scss/mixins';
@import '../../scss/svg-utils';
@import './../../scss/imports';

.main-navigation {
	background:$white;
	overflow-y:auto;

	&__expand-label {
		flex:1 1 auto;
		display:flex;
		flex-direction: row;
		align-items: center;
	}

	&__expand-text {
		flex:1 1 auto;
	}

	&__expand-icon {
		flex:0 0 auto;
	}

	&-header {
		background-color:$grey;
		color:$white;
		padding: 18px;

		&__close-line {
			position:sticky;
			background-color:$grey;
			color:$white;
			padding: 18px;
			top:0;
			display:flex;
			flex-direction: row;
			justify-content: flex-end;
		}

		&__close-link {
			@include link-inherit-color;

			margin:-8px;
		}

		&__account-label {
			font-weight:$weight-bold;
			font-size:20px; // stylelint-disable-line
		}

		&__account-id {
			font-weight:$weight-bold;
			font-size:14px; // stylelint-disable-line
			text-transform: uppercase;
		}
	}

	@include until($desktop) {
		border-top-right-radius: 8px;

		&-header {
			padding: 16px;
			padding-top:0;

			&__close-line {
				border-top-right-radius: 8px;
				padding:16px;
			}
		}
	}

	@include from($desktop) {
		&-header {
			&__close-line,
			&__account-id {
				display:none;
			}
		}
	}
}
