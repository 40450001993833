// Entry point for importing non-rendering scss.
// Import this file if you'd like to use bulma variables, mixins, etc in another component or project without dealing with all of the rendered bulma css

// Import functions, required by some vars
@import '~bulma/sass/utilities/functions';

// Import inputs & variables first so they'll override bulma defaults
@import '../../scss/inputs';
@import '../../scss/variables';

// Import Bulma's utils (default variables, mixins, etc)
@import '~bulma/sass/utilities/_all';

// Custom mixins, functions
@import '../../scss/mixins';
@import '../../scss/svg-utils';

@import './../../scss/imports';

.error-block {
	background: $danger-light;
	padding:1rem;
	display:flex;
	flex-direction: row;
	border-radius:.5rem;

	&__icon {
		padding:.25rem .5rem 0 0;
	}

	&__content {
		flex:1 1 auto;
	}
}
