// Typography
// This file should contain typography-related vars, including font-family lists for any webfonts used. It should *not* contain @font-face declarations, those belong in scss/fonts.

$system-sans: blinkmacsystemfont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

$family-lato: 'Lato', #{$system-sans};
$family-merriweather: 'Merriweather', #{$system-sans};

// Typography

$family-sans-serif: $family-lato;
$family-serif: $family-merriweather;
$family-monospace: monospace;
$render-mode: optimizeLegibility;

$size-0: 4.5rem !default;
$size-1: 3rem !default;
$size-2: 2.5rem !default;
$size-3: 30px !default;
$size-4: 1.5rem !default;
$size-5: 1.25rem !default;
$size-6: 1rem !default;
$size-7: .875rem !default;
$size-8: .75rem !default;

$weight-light: 300;
$weight-normal: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;
$weight-extrabold: 800;
$weight-heavy: 900;

$family-primary: $family-sans-serif;
$family-secondary: $family-serif;
$family-code: $family-monospace;
$family-headline: $family-lato;

$size-small: $size-8;
$size-normal: $size-6;
$size-medium: $size-5;
$size-large: $size-4;
$size-huge: $size-0;

$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7 $size-8;

$title-family: $family-headline;
$subtitle-family: $family-headline;
$title-weight: $weight-bold;
$subtitle-weight: $weight-normal;
$content-heading-weight: $weight-semibold;

$button-font-weight:$weight-semibold;
$navbar-item-font-weight:$weight-normal;
