$navbar-height: 3.75rem;
$navbar-item-padding-horizontal: 27px;
$navbar-padding-vertical: .75rem;
$navbar-item-fat-padding-horizontal: 45px;
$navbar-dropdown-item-padding-vertical: .75rem;
$navbar-dropdown-item-padding-horizontal: 1rem;

$footer-padding: 3rem 1.5rem;

$box-padding: 3em;

$menu-nested-list-margin: .75em !default;
$menu-nested-list-padding-left: .5em !default;
$menu-list-border-left: 2px solid $secondary;
$menu-item-active-color: $primary-invert;
$menu-item-active-background-color: $primary;

$gradient-button: linear-gradient(301.89deg, $gradient-color-1 0%, $gradient-color-2 44.85%, $gradient-color-3 103.81%);
$gradient-button-active: linear-gradient(301.89deg, $gradient-color-1-dark 0%, $gradient-color-2-dark 44.85%, $gradient-color-3-dark 103.81%);
$gradient-button-shadow-hover: 0 4px 20px rgba(22, 83, 187, .3);
$gradient-button-shadow-active: 0 -4px 8px rgba(59, 145, 190, .3), 0 4px 8px rgba(16, 54, 106, .3);

$input-focus-box-shadow-size: 0 0 8px;
$input-focus-box-shadow-color: rgba($blue-darker, .5);
$input-focus-border-color: $blue-darker;

$checkbox-active-background-color: $primary-dark;

$pagination-color:$grey;
$pagination-item-margin: .25rem 1px;
$pagination-item-font-size: .875em;
$pagination-item-padding: .36em;
$pagination-item-padding-left: $pagination-item-padding;
$pagination-item-padding-right: $pagination-item-padding;
$pagination-border-color: rgba(0, 0, 0, 0);
$pagination-hover-border-color: rgba(0, 0, 0, 0);

$modal-radius: 16px;
$modal-background-color:$white;

$modal-background-background-color: rgba(64, 64, 65, .5);
$modal-card-head-radius: $modal-radius;
$modal-card-head-background-color:$modal-background-color;
$modal-card-head-border-bottom: 0;
$modal-card-foot-radius: $modal-radius;
$modal-card-foot-background-color:$modal-background-color;
$modal-card-foot-border-top: 0;
$modal-card-body-background-color:$modal-background-color;

$radio-active-background-color:$link;
