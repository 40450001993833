// Entry point for importing non-rendering scss.
// Import this file if you'd like to use bulma variables, mixins, etc in another component or project without dealing with all of the rendered bulma css

// Import functions, required by some vars
@import '~bulma/sass/utilities/functions';

// Import inputs & variables first so they'll override bulma defaults
@import '../../../src/scss/inputs';
@import '../../../src/scss/variables';

// Import Bulma's utils (default variables, mixins, etc)
@import '~bulma/sass/utilities/_all';

// Custom mixins, functions
@import '../../../src/scss/mixins';
@import '../../../src/scss/svg-utils';
.lending-layout {
	$left-nav-width:230px;

	width:100%;
	min-height:100vh;

	&__main {
		// padding-top:$navbar-height;
		width:100%;
		height:100%;
		min-height:90vh;
		display:flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: stretch;
	}

	&__view {
		flex:1 1 auto;
		width:100%;
	}

	@include until($desktop) {
		&__left-nav {
			width: $left-nav-width;
			z-index: $above-blur-z;
			position: fixed;
			top: 0;
			left: $left-nav-width * -1;
			bottom: 0;
			transition: left .25s;

			&.is-active {
				left: 0;
			}

			&__inner {
				height: 100%;
			}
		}
	}

	@include from($desktop) {
		&__left-nav {
			flex: 0 0 auto;
			position: relative;
			width: 0;
			transition: width .25s;

			&.is-active {
				width: $left-nav-width;
			}

			&__inner {
				width: $left-nav-width;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
			}

			&__blur {
				display: none;
			}
		}
	}
}
