// Entry point for importing non-rendering scss.
// Import this file if you'd like to use bulma variables, mixins, etc in another component or project without dealing with all of the rendered bulma css

// Import functions, required by some vars
@import '~bulma/sass/utilities/functions';

// Import inputs & variables first so they'll override bulma defaults
@import '../../scss/inputs';
@import '../../scss/variables';

// Import Bulma's utils (default variables, mixins, etc)
@import '~bulma/sass/utilities/_all';

// Custom mixins, functions
@import '../../scss/mixins';
@import '../../scss/svg-utils';

@import "./../../scss/imports";

.accounts-menu {
	&-item {
		display:flex;
		flex-direction: row;
		align-items: center;

		&__icon {
			flex:0 0 auto;
			margin-right:8px;
			width:40px;
			height:auto;
		}

		&__text {
			flex:1 1 auto;
			display:flex;
			flex-direction:column;
			align-items: stretch;
		}

		&__cta {
			@include undecorated-link;

			color:$link;
			font-size:14px; // stylelint-disable-line
			margin-top:-2px;
		}
	}

	a {
		&.is-active {
			cursor:initial;
		}
	}
}
