// Entry point for importing non-rendering scss.
// Import this file if you'd like to use bulma variables, mixins, etc in another component or project without dealing with all of the rendered bulma css

// Import functions, required by some vars
@import '~bulma/sass/utilities/functions';

// Import inputs & variables first so they'll override bulma defaults
@import '../scss/inputs';
@import '../scss/variables';

// Import Bulma's utils (default variables, mixins, etc)
@import '~bulma/sass/utilities/_all';

// Custom mixins, functions
@import '../scss/mixins';
@import '../scss/svg-utils';

@import "./../scss/imports";

.flock {
	width:100%;

	&__svg {
		@include responsive-image;

		&__notch {
			fill: $light-transparent;
		}
	}

	$variants: 'variant-one', 'variant-two', 'variant-three';

	@each $name, $pair in $all-colors {
		$color: $pair;

		// For every color variable provided by Groundfloor-ui
		// Allow users to set "is-<variable>" to change the fill-color of all notches
		// Ex: is-primary, is-dark-transparent
		@at-root .is-#{$name}#{&} & {
			&__svg__notch {
				fill: $color;
			}
		}

		// Also for every color variable provided by Groundfloor-ui
		// Allow users to change the fill-color of all variant notches.
		// Class can be written in the format .<variant>-<color-name>
		// Ex: .variant-one-primary, .variant-three-dark-transparent
		//
		// ◥ ◥ v2 v1
		//   ◥ v3 ◥
		//      ◥ ◥
		//        ◥
		@each $variant in $variants {
			@at-root .#{$variant}-#{$name}#{&} & {
				&__svg__notch.#{$variant} {
					fill: $color;
				}
			}
		}
	}
}
