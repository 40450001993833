// Entry point for importing non-rendering scss.
// Import this file if you'd like to use bulma variables, mixins, etc in another component or project without dealing with all of the rendered bulma css

// Import functions, required by some vars
@import '~bulma/sass/utilities/functions';

// Import inputs & variables first so they'll override bulma defaults
@import '../scss/inputs';
@import '../scss/variables';

// Import Bulma's utils (default variables, mixins, etc)
@import '~bulma/sass/utilities/_all';

// Custom mixins, functions
@import '../scss/mixins';
@import '../scss/svg-utils';

@import './../scss/imports';

.loading-overlay {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	z-index: $blur-z;

	.loading-background {
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		background: rgb(255 255 255 / 50%);

		.loading-icon {
			position: relative;
		}

		.loading-icon::after {
			animation: spinAround 500ms infinite linear;
			border-radius: 9999px;
			border-right-color: transparent;
			border-top-color: transparent;
			content: '';
			display: block;
			position: absolute;
			top: calc(50% - 1.5em);
			left: calc(50% - 1.5em);
			width: 3em;
			height: 3em;
			border-width: .25em;
		}
	}
}

.is-full-page {
	position: fixed;
	z-index: $blur-z;
}
